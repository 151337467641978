<template>
  <div class="Enterprise">
    <MyMenu :menuname="'other'"></MyMenu>
    <!-- 头部 -->
    <Stepbar :active="3"></Stepbar>
    <!-- 头部END -->

    <!-- 主要内容容器 -->
    <div class="content">
      <result :orderId="orderId" :isEnterpriseOrder="isEnterpriseOrder"></result>
    </div>
    <!-- 主要内容容器END -->
  </div>
</template>
<script>
import Stepbar from './item/Stepbar.vue'
import result from './item/result.vue'

export default {
  name: 'Enterprise',
  components: { Stepbar, result },
  data() {
    return {
      orderId: ''
    }
  },
  created() {
    if (this.$route.query.orderId != undefined) {
      this.orderId = this.$route.query.orderId
    }
    if (this.$route.query.isEnterpriseOrder != undefined) {
      this.isEnterpriseOrder = this.$route.query.isEnterpriseOrder
    }
  },
  computed: {},

  methods: {}
}
</script>
<style scoped>
@import '../../assets/css/index.css';
</style>
