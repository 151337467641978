<template>
  <div class="result" v-loading="loading">
    <div class="img"><img src="../../../assets/imgs/orderSure.png" /></div>
    <div class="title_dsc">
      <h2>{{ isEnterpriseOrder ? '提交订单成功！' : '提交订单成功！请签署合同' }}</h2>
      <p>如有疑问，请拨打客服热线：15021648934</p>
    </div>
    <div class="extra">
      <el-button type="primary" @click="gomyorder()" size="medium">查看订单</el-button>
      <el-button v-if="!isEnterpriseOrder" type="info" @click="handleContractOffline()" size="medium">线下签署</el-button>
      <el-button type="success" @click="getContractUrl()" v-if="!SignContract && !isEnterpriseOrder" size="medium">前去签署</el-button>
      <!-- <el-button  size="medium">返回首页</el-button> -->
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['orderId', 'isEnterpriseOrder'],
  data() {
    return {
      loading: false,
      SignContract: false
    }
  },
  watch: {},
  created() {},
  methods: {
    getContractUrl() {
      this.loading = true
      this.$axios.post('/zuwu-api/web/contract/getContractUrl', { orderId: this.orderId }).then((res) => {
        this.loading = false
        this.SignContract = true
        window.open(res.data.data.signUrl, '_blank')
      })
    },
    handleContractOffline() {
      this.$axios.post('/zuwu-api/withhold/offlineSignContract', { orderId: this.orderId }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.$router.push({ path: '/User/order' })
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    gomyorder() {
      this.$router.push({ path: '/User/order' })
    }
  }
}
</script>
<style scoped>
.result {
  background: #fff;
  width: 1100px;
  margin: 0 auto 30px;
  padding: 50px;
  text-align: center;
}
.img img {
  width: 100px;
}
.title_dsc {
  margin: 20px auto 30px;
}
.title_dsc h2 {
  font-size: 24px;
  color: #333;
  font-weight: normal;
}
.title_dsc p {
  width: 400px;
  font-size: 14px;
  margin: 30px auto 50px;
}
</style>
